<template>
  <div>
    <span class="d-block tooltip__message-1">
      {{ $t('courierSet.additionalSerivcesTooltip') }}.
    </span>
    <br>
    <span class="d-block tooltip__message-2">
      {{ $t('courierSet.additionalSerivcesTooltip2') }}.
    </span>
    <span
      class="d-block tooltip__message-3"
    >
      {{ $t('courierSet.additionalSerivcesTooltip3') }}.
    </span>
  </div>
</template>

<script>
export default {
  name: 'AdditionalServicesTooltipMessage',
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/utilities/variables";
.tooltip {
  &__message-1 {
    font-weight: 700;
  }
  &__message-2 {
    font-weight: 400;
  }
  &__message-3 {
    color: $text-lighten1;
    font-style: italic;
    font-weight: 200;
  }
}
</style>
